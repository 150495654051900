<template>
  <div
    :class="{
      'slide-content-item': !isCircle,
      'slide-content-item__small': isSmall && !isCircle,
      'slide-content-circle': isCircle,
      'slide-content-circle__small': isSmall && isCircle,
    }"
  >
    <slot></slot>
  </div>
</template>
<script>
export default{
  props: {
    isSmall: {
      type: Boolean,
      default: false,
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
<style lang="less" scoped>
.slide-content-item {
  position: relative;
  width: 100vw;
  // height: 2.613333rem; // tip: 高度写死，绝对定位后有高度。  
  height: 78 / 37.5rem;
  transition: all 0.3s;

  &__small {
    height: 78 / 2 / 37.5rem;
  }
}
.slide-content-circle {
  position: relative;
  width: 100vw;
  transition: all 0.3s;
  height: 89 / 37.5rem;

  &__small {
    height: 36 / 37.5rem;
  }
}

</style>
