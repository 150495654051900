import Vue from 'vue'
/**
 * 仅用于 SSR 首屏异步组件在 CSR 时控制占位
 */
const asyncCompHolder = {}
const asyncCompOnceFlag = {}

export function onceEventForCSR(name) {
  if (asyncCompOnceFlag[name]) {
    asyncCompOnceFlag[name] = false
    return true
  }
}

export function assignAsyncComp(asyncCompList) {
  if (!Array.isArray(asyncCompList)) throw new Error(`assignAsyncComp params error! --- [asyncCompList:${asyncCompList}]`)

  const asyncComponents = asyncCompList.reduce((curr, { name, selector, source }) => {
    if (typeof name !== 'string' || typeof selector !== 'string' || typeof source !== 'function') throw new Error(`assignAsyncComp params error! --- [name:${name}]`)

    Vue.set(asyncCompHolder, name, false)
    curr[name] = async () => {
      // 非 Node & 非 SSR landing
      const inCSR = typeof document !== 'undefined' && !document.querySelector(selector)
      asyncCompHolder[name] = inCSR
      asyncCompOnceFlag[name] = true
      const comp = await source()
      asyncCompHolder[name] = false
      return comp
    }
    return curr
  }, {})

  return {
    asyncCompHolder,
    asyncComponents
  }
}
