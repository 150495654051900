const tapclick = {
  bind(el, binding) {
    el.exec = function () {
      const data = binding.value
      data[0].apply(this, data.slice(1))
    }

    //touchstart
    el.addEventListener('touchstart', function (e) {
      binding.modifiers.stop && (e.stopPropagation())
      let t = e.touches[0]
      el.startX = t.clientX
      el.startY = t.clientY
      el.sTime = + new Date()
    })
    //touchend
    el.addEventListener('touchend', function (e) {
      binding.modifiers.stop && (e.stopPropagation())
      let t = e.changedTouches[0]
      el.endX = t.clientX
      el.endY = t.clientY
      if ((+ new Date()) - el.sTime < 300) {
        if (Math.abs(el.endX - el.startX) + Math.abs(el.endY - el.startY) < 20) {
          e.preventDefault()
          el.exec()
        }
      }
    })
  },
  componentUpdated(el, binding) {
    el.exec = function () {
      let data = binding.value
      data[0].apply(this, data.slice(1))
    }
  },
  unbind(el) {
    el.exec = null
  }
}

export default {
  tapclick
}
