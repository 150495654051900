/**
 * 搜索图文导航点击后转query功能
 * wiki.pageId=1107230416
 */

import { mapState } from 'vuex'
import { handleClickSearchFilter, formatSearchItem, handleSearchFilterQuery } from './search.common'
import { abortionInstance } from 'public/src/pre_requests/modules/productList/index.js'

const FILTER_WORDS_LIMIT = 4 // 算上搜索词 + 最多4个分布式筛选词 即5个词
const PIC_TOP_NAV2_QUERY = 'PIC_TOP_NAV2_QUERY'
export default {
  computed: {
    ...mapState({
      picTopNav2QuerySearchFilterWords: (state) =>
        state.searchFilterWords,
    }),
    // 实验组开关 只在search下生效
    picTopNav2QueryIsEnabled({ listAbtResult, catInfo }) {
      if (catInfo?.type !== 'search') return false
      return listAbtResult?.SearchNavQuery?.p?.NavQuery === 'A'
    },
    picTopNav2QueryHidePicTopNav(
      {
        picTopNav2QuerySearchFilterWords: searchFilterWords,
        picTopNav2QueryIsEnabled: isEnabled
      }
    ) {
      // 不在实验组
      if (!isEnabled) {
        return false
      }
      // 
      // 命中实验组
      return (searchFilterWords?.length || 0) >= FILTER_WORDS_LIMIT
    }
  },
  methods: {
    // 点击图文导航转query
    picTopNav2QueryDoSearch(item) {
      formatSearchItem(PIC_TOP_NAV2_QUERY, item)
      if (item.filterId && item.filterWord) {
        abortionInstance?.abort?.('picTopNav2QueryAbortFetch')
        const query = handleSearchFilterQuery({
          type: PIC_TOP_NAV2_QUERY,
          searchItem: item,
          query: this.$route.query,
          searchFilterWords: this.picTopNav2QuerySearchFilterWords
        })
        
        this.hideAllPanel?.()
        handleClickSearchFilter.bind(this)(query, PIC_TOP_NAV2_QUERY)
      }
    },
    filterPicTopNavByQuery (picTopNav) {
      const { filterAttrIds = '' } = this.catInfo || {}
      if (!filterAttrIds || !this.picTopNav2QueryIsEnabled || !picTopNav?.length) return picTopNav

      const ids = filterAttrIds.split('`').reduce((acc, str) => { 
        const list = str.split(',')
        list[1] && list[2] && acc.push(`${list[1]}_${list[2]}`)

        return acc
      }, [])

      if (!ids.length) return picTopNav

      return picTopNav.reduce((acc, item) => { 
        const navs = item.navs || []

        acc.push({
          ...item,
          navs: navs.filter(obj => obj.nav_id && !ids.includes(obj.nav_id))
        })

        return acc
      }, [])
    }
  }
}
