/* 
  * 职责： 用于动态变化时进行计算。
  *1.获取每次的容器总宽度，每个item的位置，返回一个数组。 
  *2.为了优化页面， 做了缓存， 容器的总宽度做为唯一key进行判断是否已经有存在过值了。  暂时业务够用。  
  *3.小注意点： 动态切换内容变了，总容器宽度和上一次一样， 可能子内容每一个的宽度不一样。 这时拿的是缓存之前的（但这种情况一般不会有）。
 */
  
class DynamicSlide {
  constructor(el, spaceBetween) {
    this.el = el
    this.spaceBetween = spaceBetween // 每个元素的间距 px
    this.gatherWidth = new Map()
    this.widths = []  // 当前移动集合 用于判断当前滚动到哪一个元素上了
    this.init()
  }

  init() {
    if (!this.el) return
    const listWidth = this.el.getBoundingClientRect?.().width || 0
    if (!listWidth) return
    
    this.widths = []
    if (this.gatherWidth.has(listWidth)) {
      this.widths = this.gatherWidth.get(listWidth)
    } else {
      // 获取每一个子元素的站位宽度
      const nodes = this.getNodes(this.el)
      this.widths = this.getNodeOffsetWidth(nodes)
      this.gatherWidth.set(listWidth, this.widths)
    }
  }

  getNodes() {
    const nodes = this.el.childNodes || []
    return [...nodes].reduce((arr, node) => {
      if (node.nodeType === 1) {
        arr.push(node)
      }
      return arr
    }, [])
  }

  getNodeOffsetWidth(nodes) {
    const list = [0]
    nodes.forEach((node, i) => {
      const curItemWidth = node.offsetWidth + this.spaceBetween
      const moveX = curItemWidth + list[i]
      list.push(moveX)
    })
    return list
  }

  // reset() {
  //   this.gatherWidth = new Map()
  // }
}

export default DynamicSlide
