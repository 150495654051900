var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"refContainer",class:{
    'fixed': _vm.isFixed,
    'scroll-fixed-container': true,
    'sticky': !_vm.isFixed
  },style:(_vm.isFixed ? {} : _vm.zIndexStyle)},[_c('div',{ref:"refContent",staticClass:"content",style:(_vm.isFixed ? {
      ..._vm.zIndexStyle,
      top: `${_vm.stickyTop === -1 ? _vm.scrollTop : _vm.stickyTop }px`,
      transition: 'top 0.3s ease'
    } : {})},[_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"skeleton",style:({height: `${_vm.skeletonHeight || _vm.mySkeletonHeight}px`})})])
}
var staticRenderFns = []

export { render, staticRenderFns }