<template>
  <div 
    v-tapclick="[onClick]" 
    :style="itemStyle"
  >
    <slot></slot>
  </div>
</template>

<script>
import directives from './directives'
export default {
  directives,
  inject: ['slideTab'],  // 父容器传递
  props: {
    slideId: {
      type: Number,
      default: -1,
      required: true, // 必传
    },
    // 禁止点击
    disabled: Boolean
  },
  computed: {
    itemStyle() {
      const style = {
        ...this.activeStyle,
      }
  
      style[this.getDirectionLabel(`marginLeft`)] = 
        this.slideId !== 0 ? `${this.slideTab.spaceBetween}px` : 0 // 间距
      return style
    },
    activeStyle() {
      return this.currentItem ? this.slideTab.activeStyle : {}
    },
    currentItem() {
      return this.slideTab.value === this.slideId
    },
  },
  methods: {
    onClick() {
      if (!this.slideTab.invertSelect && this.currentItem || this.disabled) return
      let curId = this.slideId
      let flag = true

      if (this.slideTab.invertSelect && this.currentItem) {
        // 可反选
        curId = -1000
        flag = false
      }

      this.slideTab.$emit('input', curId, flag) // 双向绑定
      this.$emit('change', this.slideId, flag)
    },

    getDirectionLabel(property) {
      if (!this.slideTab.reverse) {
        return property
      }
      return {
        'marginLeft': 'marginRight'
      }[property]
    },
  },
}
</script>
