<template>
  <div
    id="product-list-filter-wrapper"
    ref="refFilterWrapper"

    :class="productListFilterClass"
    class="product-list-filter sticky-filter-wrapper"
  >
    <!-- 图文导航 -->
    <template
      v-if="!(filterConfig.hidePicTopNav || picTopNav2QueryHidePicTopNav)"
    >
      <ClientOnly>
        <ListSkeleton
          v-if="!show"
          :type="'pictopnav'"
          :style="{ height: '3.3rem'}"
        />
      </ClientOnly>
      <ScrollFixed
        v-if="isShowPicTopNav"
        id="scrollfixed-pictop-navbar"
        ref="refFixedPicTopNav"
        :scroll-el="scrollEl"
        :scroll-top="scrollTopMap[TYPE_MAP.PIC_TOP_NAV]"
        :sticky-top="stickyTopMap[TYPE_MAP.PIC_TOP_NAV]"
        :z-index="zIndexMap[TYPE_MAP.PIC_TOP_NAV]"
        :is-config-sticky="filterConfig.isStickyPicToNav"
        :skeleton-height="picTopNavSkeletonHeight"
        @fixedChange="(flag) => handleFixedChange(TYPE_MAP.PIC_TOP_NAV, flag)"
      >
        <PicTopNav
          ref="PicTopNav"
          :pic-top-tabs="filterPicTopNav"
          :is-small="isSmallPicTopNav"
          :locals="locals"
          :cat-info="catInfo"
          :next-text="language.SHEIN_KEY_PWA_21218"
          :list-abt-result="listAbtResult"
          :language="language"
          :is-sticky="!!picTopNavIsSticky"
          :is-show-bg-banner="isShowBgBanner"
          :to-query="picTopNav2QueryIsEnabled"
          :hidden-bottom-gap="!!(showOneClickTag && isStickyOneClickTag)"
          @hook:mounted="handlePicNavCSR"
          @updatePicNavHeight="updatePicNavHeight"
          @selectPicTopNav="selectPicTopNav"
        />
        <ClientOnly>
          <s-network-error-alert :description="language.SHEIN_KEY_PWA_31743" />
        </ClientOnly>
      </ScrollFixed>
    </template>

    <ScrollFixed
      id="scrollfixed-filter-navbar"
      ref="refFixedFilter"
      :skeleton-height="42"
      :scroll-el="scrollEl"
      :scroll-top="scrollTopMap[TYPE_MAP.FILTER]"
      :sticky-top="stickyTopMap[TYPE_MAP.FILTER]"
      :z-index="zIndexMap[TYPE_MAP.FILTER]"
      :is-config-sticky="filterConfig.isStickyFilter"
      @fixedChange="(flag) => handleFixedChange(TYPE_MAP.FILTER, flag)"
    >
      <ClientOnly>
        <!-- 写多个s-network-error-alert是为了不同场景下保持一样的位置吸顶，看不懂这里的吸顶代码 -->
        <s-network-error-alert
          v-if="!isShowPicTopNav"
          :description="language.SHEIN_KEY_PWA_31743"
        />
      </ClientOnly>
      <SFilter
        v-if="navConfig && navConfig.length"
        ref="refFilter"
        v-bind="$attrs"
        :nav-config="navConfig"
        :bottom-border="false"
        :nav-bar-config="navBarConfig"
        :cur-page-refresh="curPageRefresh"
        :constant-data="constantData"
        :filter-bar-popups-visible="filterBarPopupsVisible"
        :is-rendering-panel="isRenderingPanel"
        :filter-num="filterNum"
        :is-product-list="true"
        @hook:mounted="handleComponentMouted('filterName')"
        @clickBar="clickBar"
        @onChange="filterChange"
        @clickSort="clickSort"
        @closeCloudTagPanel="closeCloudTagPanel"
        @spreadFilterItem="!isFilterfocus && (isFilterfocus = true)"
        @renderPanel="onRenderPanel('filter', $event)"
      >
        <template v-if="filterBarPopupsVisible">
          <Panel
            v-if="isExportFilter || topTwoData.topOne"
            ref="refSortPanel"
            show-next-tick
            :loading="curPageRefresh"
            @show="panelShow"
            @hook:mounted="onPanelMounted('sort')"
          >
            <SelectMenu
              v-if="sortMap.length"
              :default-select-index="sortDefaultIndex"
              :menu-list="sortMap"
              :loading="curPageRefresh"
            />
          </Panel>
          <template v-if="!isExportFilter">
            <Panel
              v-for="key in Object.keys(topTwoData)"
              ref="refTopTwoPanel"
              :key="key"
              show-next-tick
              :loading="curPageRefresh"
              @show="panelShow"
              @hook:mounted="onPanelMounted('topTwoData' + key)"
            >
              <SelectMenu
                v-if="topTwoData[key].type == 'select'"
                :default-select-index="dailyDatesDefaultIndex"
                :menu-list="topTwoData[key].data"
                :loading="curPageRefresh"
              />
              <SingleSelect
                v-else-if="topTwoData[key].type == 'single'"
                :data="topTwoData[key].data"
                :sum="sum"
                :title="language.SHEIN_KEY_PWA_14980"
                type="tree"
                :url-selected-id="queryParams.child_cat_id"
                :language="language"
                :close-bottom-left="closeBottomLeft"
                :loading="curPageRefresh"
              />
              <MultiSelect
                v-else-if="topTwoData[key].type == 'tsp'"
                :all-data="tspsData"
                :cur-data="topTwoData[key].data"
                :sum="sum"
                :data-type="topTwoData[key].type"
                :url-selected-id="activeTsps[topTwoData[key].data.id]"
                :language="language"
                :close-bottom-left="closeBottomLeft"
                :loading="curPageRefresh"
              />
              <MultiSelect
                v-else-if="(topTwoData[key].type == 'multi' || topTwoData[key].type == 'img')"
                :all-data="attrs"
                :cur-data="topTwoData[key].data"
                :sum="sum"
                :data-type="topTwoData[key].type"
                :url-selected-id="activeAttrs[topTwoData[key].data.id]"
                :language="language"
                :close-bottom-left="closeBottomLeft"
                :loading="curPageRefresh"
              />
            </Panel>
          </template>
          <Panel
            ref="refFilterPanel"
            :animation="{ direction: 'rtl' }"
            display-mode="fullscreen"
            show-next-tick
            @hook:mounted="onPanelMounted('filter_sidebar')"
            @show="(v) => sideFilterShow = v"
          >
            <SideFilter
              ref="sideFilter"
              :menu-bar-config="menuBarConfig"
              :menu-items-config="menuItemsConfig"
              :sum="sum"
              :reset-data.sync="resetData"
              :language="language"
              :show-left-filter="filterConfig.filterLeftShow"
              :left-echo-num="true"
              :need-init.sync="firstIn"
              :close-bottom-left="closeBottomLeft"
              :is-product-list="true"
              :loading="curPageRefresh"
            />
          </Panel>
        </template>
      </SFilter>
    </ScrollFixed>

    <ScrollFixed
      id="scrollfixed-cloud-tags"
      ref="refFixedCloudTags"
      :skeleton-height="42"
      :scroll-el="scrollEl"
      :scroll-top="scrollTopMap[TYPE_MAP.CLOUD_TAGS]"
      :sticky-top="stickyTopMap[TYPE_MAP.CLOUD_TAGS]"
      :z-index="zIndexMap[TYPE_MAP.CLOUD_TAGS]"
      :is-config-sticky="filterConfig.isStickyCloudTags"
      @fixedChange="(flag) => handleFixedChange(TYPE_MAP.CLOUD_TAGS, flag)"
    >
      <CloudTags
        v-if="cloudTagParams.data.length"
        ref="refCloudTags"
        :attrs="attrs"
        :is-small-style="true"
        :cloud-tags="cloudTagParams"
        :query-params="queryParams"
        :default-selected-id="activeAttrCloudTagId || queryParams.tag_ids || queryParams.pic_cate_id"
        :constant-data="constantData"
        :reload="notFilterCloudTags"
        :filter-bar-popups-visible="filterBarPopupsVisible"
        :is-rendering-panel="isRenderingPanel"
        :split-goods-render="splitGoodsRender"
        :is-auto-sticky="true"
        :auto-sticky-params="myAutoStickyParams"
        :loading="curPageRefresh"
        @hook:mounted="handleComponentMouted('cloudTagsName')"
        @onReload="notFilterCloudTags = false"
        @cloudTagsChange="filterChange"
        @onChange="cloudTagFilterChange"
        @clicAttrTag="clicAttrTag"
        @autoStickyAfter="onAutoStickyAfter"
        @autoStickyBefore="onAutoStickyBefore"
        @renderPanel="onRenderPanel('cloudTags', $event)"
      >
        <template v-if="filterBarPopupsVisible">
          <Panel
            v-for="(item) in attrCloudTags"
            ref="refCloudTagsPanel"
            :key="item.label"
            :item="item"
            show-next-tick
            :loading="curPageRefresh"
            @show="panelShow"
            @hook:mounted="onPanelMounted('cloudTags_' + item.label)"
          >
            <SelectMenu
              v-if="item.type == 'select'"
              border-top
              :default-select-index="dailyDatesDefaultIndex"
              :menu-list="item.data"
              :loading="curPageRefresh"
            />

            <!-- 新category -->
            <template v-if="isNewCategory && item.type == 'single'">
              <DropdownSelect
                border-top
                :panelShow="item.active"
                :data="item.data"
                :sum="sum"
                :url-selected-id="queryParams.child_cat_id"
                :language="language"
                :close-bottom-left="closeBottomLeft"
                :loading="curPageRefresh"
              />
            </template>
            <!-- 旧category -->
            <template v-else-if="!isNewCategory && item.type == 'single'">
              <SingleSelect
                border-top
                :data="item.data"
                :sum="sum"
                :title="language.SHEIN_KEY_PWA_14980"
                type="tree"
                :url-selected-id="queryParams.child_cat_id"
                :language="language"
                :close-bottom-left="closeBottomLeft"
                :loading="curPageRefresh"
              />
            </template>

            <MultiSelect
              v-else-if="item.type == 'tsp'"
              border-top
              :all-data="tspsData"
              :cur-data="item.data"
              :sum="sum"
              :data-type="item.type"
              :url-selected-id="activeTsps[item.data.id]"
              :language="language"
              :close-bottom-left="closeBottomLeft"
              :no-title="true"
              :loading="curPageRefresh"
            />
            <MultiSelect
              v-else-if="item.type == 'multi' || item.type == 'img'"
              border-top
              :all-data="attrs"
              :cur-data="item.data"
              :sum="sum"
              :data-type="item.type"
              :url-selected-id="activeAttrs[item.data.id]"
              :language="language"
              :close-bottom-left="closeBottomLeft"
              :no-title="true"
              :loading="curPageRefresh"
            />
            <RangeChange 
              v-else-if="item.type == 'price'" 
              border-top
              :range="priceRange"
              :language="language"
              :close-bottom-left="closeBottomLeft"
              :loading="curPageRefresh"
            />
          </Panel>
        </template>
      </CloudTags>
    </ScrollFixed>
    <!-- 一键购标签 -->
    <ScrollFixed
      ref="refFixedOneClickTag"
      :skeleton-height="oneClickTagSkeletonHeight"
      :scroll-top="oneClickTagDynamicTop"
      :sticky-top="oneClickTagDynamicTop"
      :z-index="zIndexMap[TYPE_MAP.ONE_CLICK_TAG]"
      :is-config-sticky="isStickyOneClickTag"
      @fixedChange="(flag) => handleFixedChange(TYPE_MAP.ONE_CLICK_TAG, flag)"
    >
      <div 
        id="oneclickpay_hint" 
        style="visibility:hidden; position:absolute;"
      ></div>
      <OneClickTag
        v-if="showOneClickTag"
        :constant-data="constantData"
        :one-click-tag="bannerTag"
        :tag-id="queryParams.tag_ids"
        :actived-one-click-tag="activedOneClickTag"
        :language="language"
        :visible="oneClickTagVisible"
        @change="handleChangeOneClickTagId"
      />
    </ScrollFixed>
    <ClientOnly>
      <OneClickPayGuide 
        v-if="showOneClickTag"
        :visible="bannerTag?.showTag === 1"
        :z-index="zIndexMap[TYPE_MAP.PIC_TOP_NAV]"
        :language="language"
        @close="oneClickTagVisible = true"
      />
    </ClientOnly>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
import constantDataMixin from 'public/src/pages/components/filter_new/mixins/constantDataMixin'
import scrollFixMixin from '../js/scrollFixMixin'
import MoutedQueue from '../js/moutedQueue'

import { NetworkErrorAlert as SNetworkErrorAlert } from '@shein/sui-mobile'
import SFilter from 'public/src/pages/components/filter_new/Filter.vue'
import CloudTags from '../../components/filter_new/components/CloudTags.vue'
import SideFilter from 'public/src/pages/components/filter_new/components/SideFilter'
import ListSkeleton from './ListSkeleton.vue'
import PicTopNav from './picTopNav/index.vue'
import ScrollFixed from 'public/src/pages/components/filter_new/components/ScrollFIxed.vue'
import { formatTree, formatMuitlData, filterSingleLink, getDropdownDataList } from 'public/src/pages/components/filter_new/common/format'
import { onceEventForCSR } from 'public/src/services/lazyComponent/asyncComp'
import { analysis, handleClickBarAnalysis } from '../analytics/filter'
import { NAVBAR_EXPORT, NAVBAR_PANEL, NAVBAR_QUICK } from '../../components/filter_new/utils/constants'
import ClientOnly from 'vue-client-only'
import picTopNav2QueryMixin from '../js/picTopNav2QueryMixin'
import { handleNavConfOfSort } from '../js/exportFilterSort'
import { isShein } from '../js/utils'
import { markPoint } from 'public/src/services/mark/index.js'
import { handleCloudTagsByProductTypeSort, handleCloudTagsByTagCloudSlot } from 'public/src/pages/components/filter_new/components/cloud_tags/utils/index.js'

export default {
  name: 'FilterBar',
  components: {
    SNetworkErrorAlert,
    SFilter,
    CloudTags,
    SideFilter,
    ListSkeleton,
    ClientOnly,
    PicTopNav,
    ScrollFixed,
    Panel: () => import(/* webpackChunkName: "plv2_sFilterBar" */'public/src/pages/components/filter_new/components/Panel'),
    SelectMenu: () => import(/* webpackChunkName: "plv2_sFilterBar" */'public/src/pages/components/filter_new/components/SelectMenu'),
    SingleSelect: () => import(/* webpackChunkName: "plv2_sFilterBar" */'public/src/pages/components/filter_new/components/SingleSelect'),
    DropdownSelect: () => import(/* webpackChunkName: "plv2_sFilterBar" */'public/src/pages/components/filter_new/components/DropdownSelect'),
    MultiSelect: () => import(/* webpackChunkName: "plv2_sFilterBar" */'public/src/pages/components/filter_new/components/MultiSelect'),
    RangeChange: () => import(/* webpackChunkName: "plv2_sFilterBar" */'@/public/src/pages/components/filter_new/components/RangeChange.vue'),
    OneClickPayGuide: () => import(/* webpackChunkName: "OneClickPayGuide" */ './OneClickPayGuide.vue'),
    OneClickTag: () => import(/* webpackChunkName: "plv2_OneClickTag" */ './OneClickTag.vue'),
  },
  mixins: [constantDataMixin, scrollFixMixin, picTopNav2QueryMixin],
  props: {
    from: {
      type: String,
      default: ''
    },
    invisible: Boolean,
    curPageRefresh: Boolean,
    interception: Boolean,
    show: Boolean,
    query: {
      type: [Object, undefined],
      default: undefined
    },
    filterDynamicTop: {
      type: [Number, null],
      default: null
    },
    cloudTagsDynamicTop: {
      type: [Number, undefined],
      default: undefined
    },
    isShowBgBanner: {
      type: Boolean,
      default: false
    },
    splitGoodsRender: {
      type: Boolean,
      default: true
    },
    bannerTag: {
      type: Object,
      default: () => ({})
    },
    forceInitQuery: {
      type: Boolean,
      default: false
    }
  },
  data () {
    this.isShein = isShein()
    return {
      oneClickTagVisible: false,
      notFilterCloudTags: false, // 重新计算标签云滚动
      firstIn: false,
      dailyDates: [], // daily dates
      cate: {},
      attrs: [],
      cloudTagParams: { // 标签云数据
        data: [],
        curValue: ''
      },
      attr: {},
      icons: { normal: 'sui_icon_more_down_12px_2', active: 'sui_icon_more_up_12px_2', isSuiIcon: true },
      menuBarConfig: [],
      menuItemsConfig: [],
      resetData: false,
      topTwoData: {},  // 中间2个筛选坑位
      attrCloudTags: [],
      navConfig: [],
      navBarConfig: {},
      activeAttrs: {}, // 回显的属性
      sidePanelIndex: null, // 侧边筛选栏的位置
      resetQueryParams: {
        child_cat_id: '',
        attr_ids: '',
        attr_values: '',
        min_price: '',
        max_price: '',
        tsp_ids: '',
        tag_ids: '',
        mall_tag: '',
        pic_cate_id: '',
        source: '',
        source_status: ''
      },
      initialQueryParams: {
        sort: 0,
        daily: '',
        child_cat_id: '',
        attr_ids: '',
        attr_values: '',
        min_price: '',
        max_price: '',
        tag_ids: '',
        is_from_list_feeds: '',
        pic_nav_id: '',
        pic_nav_type: '',
        pic_tab_id: '',    // 图文tabId
        pic_rec_type: '',
        tsp_ids: '',
        mall_tag: '',
        pic_cate_id: '',
        billno: '',
      },
      queryParams: {
        sort: 0,
        daily: '',
        child_cat_id: '',
        attr_ids: '', // 属性ids
        attr_values: '',
        min_price: '',
        max_price: '',
        tag_ids: '', // 标签ids
        mall_tag: '', // 是否点击的mall标签
        tsp_ids: '',
        billno: '',
      },
      isAllCategoryType: false, // 图文导航所有都是品类时，列表坑位不展示品类
      rightFilterFalg: false,  // 右侧筛选触发标识
      isFilterfocus: false,    // 光标焦点是否在filter上
      activeTsps: {}, // tsp选中ids
      tspsData: [],   // tsp格式后的数据
      activeAttrCloudTagId: null, // 激活的属性标签云id
      filterBarPopupsVisible: false,
      isRenderingPanel: false,
      hasPanelShow: false, // 是否有panel展开
      sideFilterShow: false, // 侧边筛选是否展开
      picTopNavIsSticky: false,
      priceRange: {},
      picTopNavSkeletonHeight: 0,
      oneClickTagSkeletonHeight: 0,
    }
  },
  computed: {
    // 是否搜索框吸顶
    scrollFixedZIndex() {
      return this.filterConfig?.topSucking ? 8 : 12
    },
    filterData() {
      return this.ComponentState?.FilterBar?.filterData || {}
    },
    filterConfig() {
      return this.ComponentState?.FilterBar?.filterConfig || {}
    },
    isExportFilter() {
      return this.filterConfig.isExportFilter
    },
    // 排序默认选中项
    sortDefaultIndex () {
      const index = this.sortMap.findIndex(item => item.value == this.queryParams.sort)
      return index > -1
        ? index
        : this.queryParams.sort ? '' : 0
    },
    // daily dates默认选中项
    dailyDatesDefaultIndex () {
      const index = this.dailyDates.findIndex(item => item.value == this.queryParams.daily)
      return index > -1 ? index : 0
    },
    scrollTopChangeZIndex () {
      return (this?.filterPicTopNav || []).length ? 8 : null
    },
    closeBottomLeft() {
      return this.ComponentState?.ProductList?.config?.hideGoodsNum
    },
    myQuery () {
      return this.query || this.$route.query
    },
    filterNum () {
      let filterNum = 0
      this.menuItemsConfig.forEach(item => {
        if (item.dataType === 'cate') { // categroy
          let flag = false
          const { data, curValue } = item.data
          const loop = (obj) => {
            if (obj.value === curValue) {
              flag = true
              return true
            }
            if (obj.children && obj.children.length) {
              return obj.children.find(child => loop(child))
            }
            return false
          }

          data.find(obj => loop(obj, flag))
          flag && (filterNum += 1)
          
        }  else if (item.type == 'single') {
          // 查看标签是否存在
          const hasTag = item.data.data.some(tag => tag.tag_id === item.data.curValue)
          if (item.data.curValue && hasTag) {
            filterNum += 1
          }
        } else if (item.type == 'multi' || item.type == 'img') {
          let selectedNum = 0
          const selectedMulti = item.selectedMulti || []
          const data = item.data.data

          if (item.data.selectedNum || item.data.selectedNum === 0) {
            selectedNum += (item.data.selectedNum || 0)
          } else {
            if (!selectedMulti.length) return

            data.forEach(item => {
              if (item.mix_value.split('-').some(mix_value => selectedMulti.includes(mix_value))) {
                selectedNum += 1
              }
            })
          }

          filterNum += selectedNum
        } else if (item.type == 'price') {
          // startMinPrice和startMaxPrice必须都为字符串
          const { startMinPrice = '', startMaxPrice = '' } = item.data
          filterNum += (String(startMinPrice) && String(startMaxPrice)) ? 1 : 0
        } else if (item.type === 'tsp') {
          let selectedNum = 0
          const selectedMulti = item.selectedMulti || []
          const { data } = item.data
          if (item.data.selectedNum || item.data.selectedNum === 0) {
            selectedNum += (item.data.selectedNum || 0)
          } else {
            if (!selectedMulti.length) return
            data.forEach(item => {
              if (selectedMulti.includes(item.id)) {
                selectedNum += 1
              }
            })
          }

          filterNum += selectedNum
        }
      })

      return filterNum
    },
    // 计算出 panel 的个数
    panelCount() {
      let count = 0
      // SFilter
      if (this.navConfig && this.navConfig.length) {
        if (this.isExportFilter || this.topTwoData.topOne) {
          count += 1
        }
        if (!this.isExportFilter) {
          count += Object.keys(this.topTwoData).length
        }
        // 右侧抽屉必加载 panel, 所以加 1
        count += 1
      }

      if (this.cloudTagParams.data.length) {
        count += this.attrCloudTags.length
      }

      return count
    },
    // filterbar有下拉展开时且loading中 层级高于loading
    productListFilterClass({ hasPanelShow, curPageRefresh, sideFilterShow, isShein }) {
      if (!isShein) return ''
      if (!curPageRefresh) return ''
      // 侧边栏展开时，外部层级无需做变化
      if (sideFilterShow) return ''
      if (!hasPanelShow) return ''
      return `loading high-layer`
    },

    // tiledListFilter () {
    //   return this.listAbtResult?.ListFilter?.p?.TiledListFilter || 'A'
    // },
    // 校验图文数据判断是否展示
    isShowPicTopNav () {
      if (!(this?.filterPicTopNav || []).length) return false

      return Boolean(this.filterPicTopNav.find(item => item.navs?.length))
    },
    isNewCategory () {
      const { type } = this.catInfo
      const isSearchPage = type === 'search'

      if (isSearchPage) {
        return this.listAbtResult.SearchCategory?.p?.NewSearchCategory === 'B'
      } else {
        return this.listAbtResult.ListCategory?.p?.NewListCategory === 'B'
      }
    },
    // 是否外露price
    isExportPrice () {
      return this.filterConfig?.tagCloudSlotConfig?.find(item => item.tagId == '14003')
    },
    // 过滤后的图文数据
    filterPicTopNav () {
      return this.filterPicTopNavByQuery(this.picTopNav)
    },
    // 是否展示一键购标签
    showOneClickTag() {
      return (
        !!this.bannerTag?.showTag && this.bannerTag.type === 'oneClickPay'
      )
    },
    // 是否选中一键购标签
    activedOneClickTag() {
      return (
        !!this.bannerTag?.tag_id &&
        this.queryParams.tag_ids === this.bannerTag.tag_id
      )
    },
  },
  watch: {
    show () {
      // 通过show控制scroll事件绑定和解绑，防止未显示筛选栏时，触发滚动回调导致的bug
      if (this.show) {
        this.bindScrollEvent()
      } else {
        this.removeScrollEvent()
      }
    },
    filterData: {
      async handler ({ cloudTags, dailyDates, filterCates, filterAttrs, filterPrices, filterTsps, picTopNavCate }) {
        if (this.interception) return
        this.firstIn = this.catInfo?.requestType === 'firstload' || this.catInfo?.requestType === 'switchTab'
        const { min_price, max_price } = this.catInfo || {}
        analysis.exposeFilter({ filterAttrs, filterPrices: { min_price, max_price } })
        analysis.exposeCategory()

        this.initData() // 筛选数据进来，重置一些数据
        this.initQueryParamsFromUrl()

        this.handleDailyDates(dailyDates || {}) // daily dates
        this.handleCate(filterCates || []) // 分类
        this.handleTsps(filterTsps || []) // Tsp
        this.handleAttrs(filterAttrs || []) // 属性
        this.handlePriceRange(filterPrices || {}) // 价格区间
        !this.filterConfig.hidePicTopNav && this.handlePicTopNav() // 图文导航
        this.handleCloudTags(cloudTags || [], picTopNavCate, filterPrices || {}) // 标签云


        this.handleTopTwoData() // 中间2个坑位，下拉面板里面的数据
        this.handleNavConfig()  // 筛选Bar配置（标题、icon）
        this.handleFilterLeft() // 左边筛f选内容
        this.handleNavBarType()

        if (this.firstIn) {
          this.notFilterCloudTags = true
          await this.$nextTick()
          this.$refs.refFilter?.initPanelData()
          this.setAutoStickyParams()
          this.updatePicNavSkeletonHeight()
          this.oneClickTagSkeletonHeight = this.$refs?.refFixedOneClickTag?.$el.clientHeight
        } else {
          appEventCenter && appEventCenter.$once('goodsLoadedOnPageList', () => {
            this.resetCloutTagPanelHeight()
          })
        }
      },
      immediate: true
    },
    queryParams (val) {
      if (this.firstIn) return
      this.$emit('filterChange', { params: { ...val }, rightFilterFalg: this.rightFilterFalg, isFilter: true })
    },
    isFilterfocus(newVal) {
      if (newVal) {
        this.filterAllItemSpread()          // ada 当焦点在filter时， 展开所有元素
        this.$refs?.sideFilter?.$forceUpdate() // 参数重新配置后，重置组件
      }
    },
  },
  activated () {
    this.show && this.bindScrollEvent()
  },
  deactivated () {
    this.removeScrollEvent()
  },
  mounted () {
    this.init()
    this.filterConfig.isExportFilter && this.exposeSort()
  },
  methods: {
    init() {
      this.moutedQueue = new MoutedQueue({ cb: () => {
        // 等所有都加载完成后注册滚动事件
        this.initScroll()
      } })

      const srcollConfig = {
        'picTopNavName': (this?.filterPicTopNav || []).length,
        'filterName': this.filterConfig.isStickyFilter,
        'cloudTagsName': this.cloudTagParams.data.length && (this.filterConfig.isStickyCloudTags || this.filterConfig.isStickyFilter),
      }
      const listName = []
      Object.keys(srcollConfig).forEach(k => {
        if (srcollConfig[k]) {
          listName.push(k)
        }
      })
      this.moutedQueue.init(listName)
    },

    handleComponentMouted(name) {
      !this.moutedQueue && this.init()

      this.moutedQueue.examine(name)
      // 上报父组件各个子组件挂载的情况
      this.$emit('childrenMounted', name)
    },

    // 筛选进来时，重置一些配置数据
    initData () {
      this.dailyDates = []
      this.cate = {}
      this.attrs = []
      this.tspsData = []
      this.attrCloudTags = []
      this.menuBarConfig = []
      this.menuItemsConfig = []
      this.hasPriceRange = false
    },
    setPicTopNavIndex(index) {
      this.$refs.PicTopNav?.setInnerIndex(index)
    },
    initQueryParamsFromUrl () {
      const { sort, daily, child_cat_id, attr_values, tsp_ids, attr_ids, tag_ids, min_price, max_price, pic_nav_id, pic_nav_type, pic_cate_id } = this.myQuery
      if (this.firstIn || this.forceInitQuery) {
        this.queryParams = Object.assign({}, this.queryParams, this.initialQueryParams) // firstload初始化queryParams
      }
      if (sort) this.queryParams.sort = sort
      if (daily) this.queryParams.daily = daily
      if (child_cat_id) this.queryParams.child_cat_id = child_cat_id
      if (tsp_ids) { Object.assign(this.queryParams, { tsp_ids }) }
      if (attr_values && attr_ids) Object.assign(this.queryParams, { attr_values, attr_ids })
      if (tag_ids) this.queryParams.tag_ids = tag_ids
      if (min_price || max_price) Object.assign(this.queryParams, { min_price, max_price })
      if (pic_nav_id && pic_nav_type) {
        Object.assign(this.queryParams, { pic_nav_id, pic_nav_type })
      }
      if (pic_cate_id) this.queryParams.pic_cate_id = pic_cate_id
      this.handleActiveTsps()
    },
    clickSort({ text, data = {} }) {
      // 关闭面板
      this.closeCloudTagPanel()
      this.$refs.refFilter?.hideAllPanel()
      const index = this.navConfig.findIndex(item => item?.text === text)
      analysis.clickSort({ sort: data?.value,  sortType: text, clickType: `top${index}` })

      this.queryParams = { 
        ...this.queryParams, 
        sort: data?.value,
        ...this.getSourceInfo({ source: 'sort', sourceStatus: 1 })
      }
      this.firstIn = false
    },
    // 筛选改变
    filterChange (obj) {
      const { topOne, topTwo } = this.topTwoData
      const { data = {}, index, type, operation, curSelectedItem, cancel, curSelectedNum, originalValue } = obj
      const { value, label_en, } = data
      let filterParams = {}
      this.notFilterCloudTags = true
      this.rightFilterFalg = false
      // index为当前筛选的坑位
      if (this.isExportFilter && index === 1) {
        filterParams = this.handleSideFilterParams(obj, data)
      } else if (index || index === 0) {
        if (index == 0) {
          if (index == this.sidePanelIndex) { // 中间2个坑位无数据时
            filterParams = this.handleSideFilterParams(obj, data)
          } else {
            filterParams = { sort: value, ...this.getSourceInfo({ source: 'sort', sourceStatus: 1 }) }
            // 埋点
            analysis.clickSort({ sortType: label_en, sort: value, clickType: 'sort' })
          }
        } else if (index == 1) {
          const dataType = topOne?.dataType
          if (dataType == 'daily') {
            this.resetData = true
            filterParams = { ...this.resetQueryParams, daily: value }
            // 埋点
            analysis.clickDate({ date: value, index })
          } else if (dataType == 'cate') {
            filterParams = this.handleCateParams({ data, index, doAnalysis: operation != 'reset', isCancel: cancel, originalValue  })
          } else if (dataType == 'attr') {
            filterParams = this.handleAttrParams({ data, index, curSelectedItem, operation, curSelectedNum, clickType: 'top1' })
          } else if (dataType == 'tsp') {
            filterParams = this.handleTspsParams({ tspData: data, operation })
          }
        } else if (index == 2) {
          if (topTwo) {
            const dataType = topTwo?.dataType
            if (dataType == 'cate') {
              filterParams = this.handleCateParams({ data, index, doAnalysis: operation != 'reset', isCancel: cancel, originalValue })
            } else if (dataType == 'attr') {
              filterParams = this.handleAttrParams({ data, index, curSelectedItem, operation, curSelectedNum, clickType: 'top2' })
            } else if (dataType == 'tsp') {
              filterParams = this.handleTspsParams({ tspData: data, operation })
            }
          } else {
            filterParams = this.handleSideFilterParams(obj, data)
          }
        } else if (index == 3) {
          filterParams = this.handleSideFilterParams(obj, data)
        }
      } else {
        this.closeCloudTagPanel()
        if (type == 'cloudTag') {
          filterParams = this.changeCloudTag(data)
        } else if (type == 'QuickSearch') {
          filterParams = { sort: obj.value }
          analysis.clickSort({ page: 'productList', sort: obj.value, sortType: label_en, clickType: 'sort' })
        }
      }

      this.queryParams = { ...this.queryParams, ...filterParams }
      this.firstIn = false

      // 筛选变更时需要清除 spu 图去重缓存，避免无法再次出现 spu 图的情况
      if (this.PageState?.spuImgMap) {
        this.PageState.spuImgMap = {}
      }
    },
    // 筛选改变
    cloudTagFilterChange (obj) {
      const { data = {}, item, index, operation, curSelectedItem, cancel, curSelectedNum, originalValue } = obj
      const { dataType } = item
      const { value } = data
      let filterParams = {}
      this.notFilterCloudTags = true
      this.rightFilterFalg = false
      this.activeAttrCloudTagId = item?.value
      // index为当前筛选的坑位
      if (dataType == 'daily') {
        this.resetData = true
        filterParams = { ...this.resetQueryParams, daily: value }
        // 埋点
        analysis.clickDate({ date: value, clickType: 'label' })
      } else if (dataType == 'cate') {
        filterParams = this.handleCateParams({
          data,
          index,
          doAnalysis: operation != 'reset',
          isCancel: cancel,
          originalValue,
          clickType: 'label',
          sourceFrom: 'cloudTagPanel',
        })
      } else if (dataType == 'attr') {
        filterParams = this.handleAttrParams({ data, index, curSelectedItem, operation, curSelectedNum, clickType: `label${obj.tagIndex}`, sourceFrom: 'cloudTagPanel' })
      } else if (dataType == 'tsp') {
        filterParams = this.handleTspsParams({ tspData: data, operation, curSelectedItem, sourceFrom: 'cloudTagPanel' })
      } else if (dataType == 'price') {
        // obj.priceInput = ''
        filterParams = this.handlePriceParams({ obj, sourceFrom: 'cloudTagPanel', clickType: `label${obj.tagIndex}` })
      }

      this.queryParams = { ...this.queryParams, ...filterParams }
      this.firstIn = false

      // 筛选变更时需要清除 spu 图去重缓存，避免无法再次出现 spu 图的情况
      if (this.PageState?.spuImgMap) {
        this.PageState.spuImgMap = {}
      }
    },

    changeCloudTag({ value, mall_tag, tagIndex, isPicNavCate } = {}) {
      let filterParams = {
        tag_ids: '', // 标签云
        pic_cate_id: '', // 图文子类目
        ...this.getSourceInfo({ source: 'cloudTag', sourceStatus: value ? 1 : 0 })
      }
      if (isPicNavCate) {
        filterParams.pic_cate_id = value
        filterParams.child_cat_id = '' // 清空cate属性
      } else {
        filterParams.tag_ids = value
        filterParams.mall_tag = value ? mall_tag : ''
      }
      // 埋点
      this.analysisCloudTagClick({ value, tagIndex, isPicNavCate })
      this.notFilterCloudTags = false
      return filterParams
    },

    // 处理右侧弹出筛选面板参数
    handleSideFilterParams (obj, data) {
      this.rightFilterFalg = true
      const { operation, type, dataType, cancel, curSelectedNum, curSelectedItem, originalValue, tspData } = obj
      let filterData = {}

      if (operation == 'resetAll') {
        // 埋点
        analysis.sideClickReset()
        // 侧边包含促销标签时需重置
        return this.resetQueryParams
      }
      if (type == 'single') {
        if (dataType == 'cate') {
          if (filterSingleLink(this.filterData.filterCates, this.queryParams.child_cat_id).length) {
            return this.handleCateParams({
              data,
              doAnalysis: true,
              isCancel: cancel,
              originalValue,
              sourceFrom: 'sideFilter',
            })
          }
        }
        if(dataType == 'shopType') { // 商品类型标签
          return this.handleShopTypeTags({ data })
        }
      } else if (type == 'multi' || type == 'img') {
        filterData = this.handleAttrParams({ data, curSelectedItem, operation, curSelectedNum, clickType: 'Filter', sourceFrom: 'sideFilter' })
      } else if (type == 'price') {
        filterData = this.handlePriceParams({ obj, sourceFrom: 'sideFilter', clickType: 'filter' })
      } else if (type === 'tsp') {
        filterData = this.handleTspsParams({ tspData, operation, curSelectedItem, sourceFrom: 'sideFilter' })
      }

      return filterData
    },
    handleSaveCurrAttr (curSelectedNum, curSelectedItem) {
      if (curSelectedNum) {
        this.$emit('saveCurrAttr', +curSelectedItem.id)
      }
    },
    // 侧边筛选和标签云筛选面板 的source信息
    getSourceInsideFilterOrCloudTagPanel({ sourceFrom, active }) {
      const isSideFilter = sourceFrom === 'sideFilter'
      const isCloudTagPanel = sourceFrom === 'cloudTagPanel'

      let sourceInfo = {}
      const source = isSideFilter 
        ? 'insideFilter' 
        : isCloudTagPanel 
          ? 'cloudTagPanel' 
          : ''
      if (source) {
        sourceInfo = this.getSourceInfo({ source, sourceStatus: active ? 1 : 0 })
      }

      return sourceInfo
    },
    // 处理分类参数
    handleCateParams ({ data, index, doAnalysis, isCancel, clickType, sourceFrom }) {
      const { value, originalValue } = data
      const isSideFilter = sourceFrom === 'sideFilter'
      // 埋点
      if (doAnalysis) {
        analysis.clickCate({
          index,
          clickType,
          catId: value || originalValue,
          isSide: isSideFilter,
          facet: this.fhContext?.categories,
          gfilter: this.googleContext?.gfilter,
          isCancel: isCancel ? 0 : 1
        })
      }

      return { child_cat_id: value, pic_cate_id: '', ...this.getSourceInsideFilterOrCloudTagPanel({ sourceFrom, active: !isCancel }) }
    },
    handleTspsParams({ tspData = [], operation, curSelectedItem, sourceFrom }) {
      const ids = tspData.map(item => item.id)
      const tsp_ids = ids.join(',')
      // 埋点
      if (operation != 'reset') {
        analysis.clickTsps({ tsps: tsp_ids })
      }

      return { tsp_ids, ...this.getSourceInsideFilterOrCloudTagPanel({ sourceFrom, active: curSelectedItem.active }) }
    },
    // 处理属性参数
    handleAttrParams ({ data, index, clickType, curSelectedItem, operation, curSelectedNum, sourceFrom }) {
      let selectedAttrsWithHotMark = [] // 带有hot标识符
      const attrIds = []
      const attrValues = []
      data.forEach(item => {
        const attrs = item.mix_value.split('-')
        selectedAttrsWithHotMark = selectedAttrsWithHotMark.concat(attrs)
        attrIds.push(item.mix_value)
        attrValues.push(item.label.replace('-', '')) // one-size 等特殊情况去掉中横线
      })

      // 埋点
      if (operation != 'reset') {
        analysis.clickAttr({
          selectedAttrs: selectedAttrsWithHotMark.join('-'),
          index,
          clickType,
          curSelectedItem,
          facet: this.fhContext?.[`attr${curSelectedItem.id}`],
          gfilter: this.googleContext?.gfilter,
        })
      }

      this.handleSaveCurrAttr(curSelectedNum, curSelectedItem) // 保留当前属性项

      return { attr_ids: attrIds.join('-'), attr_values: attrValues.join('-'), ...this.getSourceInsideFilterOrCloudTagPanel({ sourceFrom, active: curSelectedItem.active }) }
    },
    // 处理价格参数
    handlePriceParams ({ obj, sourceFrom, clickType }) {
      const { minPrice, maxPrice, priceInput, startMinPrice, startMaxPrice } = obj
      let min_price = ''
      let max_price = ''
      if (minPrice !== startMinPrice || maxPrice !== startMaxPrice) {
        min_price = startMinPrice
        max_price = startMaxPrice
      }
      // 埋点
      analysis.dragPriceRange({
        startMinPrice,
        startMaxPrice,
        clickType,
        priceInput,
        facet: this.fhContext?.min_price,
        facet2: this.fhContext?.max_price,
        gfilter: this.googleContext?.gfilter,
      })

      return { min_price, max_price, price_input: priceInput, ...this.getSourceInsideFilterOrCloudTagPanel({ sourceFrom, active: 1 }) }
    },
    // 将属性值转换成标签
    handleAttrToTags() {
      if (!this.isExportFilter) {
        this.attrCloudTags = []

        return []
      }

      let list = this.sortByTst(this.filterAttrFromPicTopNav()).map(item => this.getConfigByAttr(item))

      if (this.cate?.data?.length) {
        list.unshift({ type: 'single', dataType: 'cate', data: this.cate })
      }

      if (this.dailyDates.length) {
        list.unshift({
          type: 'select',
          dataType: 'daily',
          data: this.dailyDates
        })
      }

      const { child_cat_id } = this.queryParams

      const attrCloudTags = list.map(item => {
        const { data } = item
        data.dataType = item.dataType

        switch(item.dataType) {
          case 'daily':
            return {
              ...item,
              value: data.id,
              label: this.language.SHEIN_KEY_PWA_15190,
              iconSuffix: this.icons,
              filterType: 'date',
              panelContentSelected: true,
              selectedNum: 1
            }
          case 'cate': {
            const selectedNum = !!child_cat_id ? 1 : 0
            return {
              ...item,
              value: 'cate',
              label: this.language.SHEIN_KEY_PWA_14980,
              iconSuffix: this.icons,
              filterType: 'category',
              // disable: !data.data.length,
              panelContentSelected: selectedNum,
              selectedNum,
              active: this.activeAttrCloudTagId === 'cate'
            }
          }
          case 'tsp': {
            const selectedNum = this.activeTsps[data.id]?.length
            return {
              ...item,
              value: data.id,
              label: data?.name,
              iconSuffix: this.icons,
              filterType: 'tsp',
              // disable: !this.tspsData.find(i => i.id == data.id),
              panelContentSelected: selectedNum,
              selectedNum,
              active: this.activeAttrCloudTagId === data.id
            }
          }
          case 'attr': {
            const selectedNum = this.activeAttrs[data.id]?.length
            return {
              ...item,
              value: data.id,
              label: data?.name,
              iconSuffix: this.icons,
              filterType: 'attribute',
              // disable: !this.attrs.find(i => i.id == data.id),
              panelContentSelected: selectedNum,
              selectedNum,
              active: this.activeAttrCloudTagId === data.id
            }
          }
          default:
            return {
              ...item,
              value: data.id,
            }
        }
      })

      // const cateAttr = attrCloudTags.find(item => item.dataType === 'cate')
      // if (cateAttr && this.tiledListFilter === 'D') {
      //   attrCloudTags.splice(attrCloudTags.indexOf(cateAttr), 1)
      //   attrCloudTags.unshift(cateAttr)
      // }

      this.attrCloudTags = attrCloudTags
      return this.attrCloudTags
    },
    // 处理标签云
    handleCloudTags (cloudTags, picTopNavCate, filterPrices) {
      const curValue = this.queryParams.tag_ids || this.queryParams.pic_cate_id || ''
      const cloudTagData = this.getRevealCloudTags(cloudTags)
      const priceData = this.getPriceCloudTag(filterPrices)
      const picCateData = this.handlePicNavCate(picTopNavCate)
      const attrData = this.handleAttrToTags()
      const data = handleCloudTagsByTagCloudSlot({ 
        tagCloudSlotConfig: this.filterConfig.tagCloudSlotConfig,
        cloudTags: [...cloudTagData, ...priceData, ...picCateData, ...attrData]
      })
      this.attrCloudTags = data.filter(item => this.attrCloudTags.includes(item) || item.dataType == 'price')
      this.cloudTagParams = {
        data,
        curValue
      }

      const analysisCloudTags = data.map(item => ({ ...item, tagCate: item.filterType || 'label' }))
      // 一键购标签云
      if (this.showOneClickTag) {
        analysisCloudTags.push({
          tag_id: this.bannerTag?.tag_id,
          tagIndex: 0,
        })
      }
      analysis.exposeCloudTag({ cloudTags: analysisCloudTags }) // 标签云曝光
      this.handleSideCloudTagsConfig(cloudTags) // 侧边包需要含标签时补充配置进去
    },
    // 价格外露标签
    getPriceCloudTag (filterPrices) { 
      if (!this.isExportPrice) return []

      let selectedNum = 0
      let panelContentSelected = false

      if (Boolean(this.queryParams?.min_price) || Boolean(this.queryParams?.max_price)) {
        selectedNum = 1
        panelContentSelected = true
      }

      return [
        {
          type: 'price',
          data: filterPrices,
          iconSuffix: this.icons,
          filterType: 'price',
          dataType: 'price',
          value: 'price',
          label: this.language.SHEIN_KEY_PWA_16527,
          isInput: true,
          active: this.activeAttrCloudTagId === 'price',
          selectedNum,
          panelContentSelected,
          language: {
            to: this.language.SHEIN_KEY_PWA_27244,
            min: this.language.SHEIN_KEY_PWA_27242,
            max: this.language.SHEIN_KEY_PWA_27243,
          }
        }
      ]
    },
    // cloudTagParams排序
    // cloudTagParamsSortByAbt (list) { 
    //   // 将Category放在第一位【wiki: pageId=1178849879】
    //   if (this.tiledListFilter !== 'D') return
    //   const item = list.find(item => item.dataType === 'cate')
    //   if (!item) return
    //   list.splice(list.indexOf(item), 1)
    //   list.unshift(item)
    // },
    // 外露的标签云
    getRevealCloudTags(cloudTags) {
      if (cloudTags.length === 0) return []

      // 标签云标签是否接入ccc配置
      return Boolean(this.listAbtResult.ProductTypeLables?.p?.ShowProductTypeLables)
        ? handleCloudTagsByProductTypeSort({
          cloudTags: this.cloudTagsFilterByCccConfig(cloudTags),
          productTypeSortConfig: this.filterConfig?.productTypeSortConfig,
          filterType: 'isExposed'
        })
        : this.cloudTagsFilter(cloudTags)
    },
    // 标签云过滤条件
    cloudTagsFilter(list) {
      const isShowPromotionLabel = this.filterConfig.hasPicNavTop && !this.locals.IS_RW // 是否展示促销标签【非门店搜索结果页 & shein下】

      return list.filter(item => {
        // 展示 本地mall || qs
        if (Number(item.local_mall) === 1 || item.quickship_tag) return true
        // 优选卖家
        if (this.showPreferredSeller(item.tag_id)) return true
        // 促销标签
        if (
          isShowPromotionLabel
          && this.filterConfig.promotion_label
          && Number(item.tag_type) === 100
        ) return true

        return false
      })
    },
    // 标签云过滤 - 标签接入ccc配置
    cloudTagsFilterByCccConfig (list) {
      return list.filter(item => {
        if (item.local_mall === 0) {
          return false
        }

        return this.cloudTagsFilterInner(item)
      })
    },

    // Filter内标签云过滤条件
    cloudTagsFilterInner(item) {
      // type: 原先标签云 0 mall标签 1 quickship标签 2，tag_type：功能标签 7 促销标签 100 人工 6 推荐 4
      const TAG_TYPE = { 7: true, 100: true }
      const { tag_type, type, tag_id } = item
      if(+type === 0) {
        return TAG_TYPE[tag_type] || this.showPreferredSeller(tag_id)
      }
      return true
    },
    handlePicNavCate(picTopNavCate = []) {
      const list = []
      // 实验组 B 不展示图文子分类
      // if (!picTopNavCate.length || this.showLabelCloudsExpData === 'B') return list
      picTopNavCate.forEach(item => {
        list.push({
          isPicNavCate: true,
          value: item.cat_id,
          label: item.cat_name,
        })
      })
      return list
    },
    // Filter内标签云
    handleSideCloudTagsConfig(cloudTags) {
      const cloudTagsSideData = handleCloudTagsByProductTypeSort({
        cloudTags: cloudTags.filter((item) => this.cloudTagsFilterInner(item)),
        productTypeSortConfig: this.filterConfig?.productTypeSortConfig,
        filterType: 'filterExposed'
      })

      if (!cloudTagsSideData?.length) return

      this.menuBarConfig.unshift({
        text: this.language.SHEIN_KEY_PWA_18695, // 商品类型
        active: true,
        count: true,
      })

      this.menuItemsConfig.unshift({
        type: 'single',
        dataType: 'shopType', // 商品类型
        collapse: 2,
        data: {
          data: cloudTagsSideData,
          curValue: this.queryParams.tag_ids
        },
      })
    },
    showPreferredSeller(tagId) {
      return tagId == '60006881' && this.listAbtResult?.AllListPreferredSeller?.p == 'type=B'
    },
    // 处理daily-new
    handleDailyDates (dailyDates) {
      if (!Object.keys(dailyDates).length) return

      const time = dayjs(Date.now())
      const today = time.format('YYYY-MM-DD')
      const yesterday = time.add(-1, 'days').format('YYYY-MM-DD')

      if (dailyDates[today]) dailyDates[today] = this.language.SHEIN_KEY_PWA_16288 // Today 多语言文案
      if (dailyDates[yesterday]) dailyDates[yesterday] = this.language.SHEIN_KEY_PWA_15855 // Yesterday 多语言文案

      for(let key in dailyDates) {
        this.dailyDates.push({ value: key, label: dailyDates[key] })
      }
      this.dailyDates.unshift({ value: '', label: this.language.SHEIN_KEY_PWA_15737 })
    },
    // 处理分类
    handleCate (filterCates) {
      const cates = filterSingleLink(filterCates, this.queryParams.child_cat_id)
      if (cates && !cates.length && this.firstIn) return

      const curValue = this.queryParams.child_cat_id

      if (!cates.length) {
        this.cate = {
          data: cates,
          curValue
        }
      } else {
        const formatedTree = formatTree({
          cat_name: 'label',
          cat_id: 'value',
          children: 'children'
        }, cates)

        if (this.isNewCategory) {
          // 新的 category 交互
          this.cate = {
            data: formatedTree,
            curValue,
            // 需要 deepClone 避免对 formatedTree 的修改影响到侧边栏 Filter 相关的功能出现 All 节点
            dropdownSelectedDataList: curValue ? getDropdownDataList(cloneDeep(formatedTree), curValue) : formatedTree,
          }
        } else {
          // 旧的 category 交互
          this.cate = {
            data: formatedTree,
            curValue,
          }
        }
      }

      this.handleSideCateConfig(cates)
    },
    // 侧边筛选栏分类配置
    handleSideCateConfig (cates) {
      if (cates && !cates.length) return

      this.menuBarConfig.push({
        text: this.language.SHEIN_KEY_PWA_14980,
        link: true,
        active: true
      })

      this.menuItemsConfig.push({
        type: 'single',
        dataType: 'cate',
        collapse: 2,
        data: this.cate,
      })
    },
    // 处理tsp
    handleTsps(tsps) {
      if (!Array.isArray(tsps) || !tsps.length) return

      this.tspsData = formatMuitlData({
        tagGroupId: 'id',
        tagGroupName: 'name',
        tagList: 'data',
        dataItem: {
          tagId: 'id',
          tagName: 'label',
        }
      }, tsps)

      const activeIndexTsp = [0, 1] // 默认展开前两项
      this.tspsData.forEach((attr, i) => {
        if (this.activeTsps[attr.id]) {
          activeIndexTsp.push(i) // 有选中项就需要展开
        }

        this.menuBarConfig.push({
          text: attr.name,
          count: true,  // 选中后的数量
          active: activeIndexTsp.includes(i),
        })

        this.menuItemsConfig.push({
          type: 'tsp',
          collapse: i < 2 ? 2 : 0, // 超过多少行收齐
          data: attr,
          selectedMulti: this.activeTsps[attr.id] || [],
        })
      })
    },

    // 处理属性
    handleAttrs (attrs) {
      if (attrs && !attrs.length) return

      // 只保留有数据的项
      attrs.forEach(attr => {
        if (attr.show_group) {
          attr.data = attr.groups
        } else {
          attr.data = attr.attr_values
        }
        delete attr.attr_values
        delete attr.groups
      })

      this.attrs = formatMuitlData({
        attr_id: 'id',
        attr_name: 'name',
        data: 'data',
        dataItem: {
          attr_id: 'id',
          attr_filter: 'mix_value',
          attr_value: 'label',
          group_name: 'label',
          group_image: 'label_img',
        }
      }, attrs)
      this.handleActiveAttrs()
      this.handleSideAttrConfig() // 处理侧边筛选栏属性配置

    },
    // 处理tsp数据 更新数据
    handleActiveTsps() {
      this.activeTsps = {}
      const { tsp_ids } = this.queryParams
      const tspIds = tsp_ids && typeof tsp_ids === 'string' && tsp_ids.split(',')
      if (!Array.isArray(tspIds) || !tspIds.length) return
      tspIds.forEach(item => {
        const [id] = item.split('_')
        if (Array.isArray(this.activeTsps[id])) {
          this.activeTsps[id].push(item)
        } else {
          this.activeTsps[id] = [item]
        }
      })

    },

    // 处理激活的属性项
    handleActiveAttrs () {
      this.activeAttrs = {}
      let { attr_ids } = this.queryParams
      if (!attr_ids || !attr_ids.split) return

      const idsSet = new Set((attr_ids.split('-') || []).map(item => Number(item.split('_')[0] || 0)))
      if (!idsSet.size) return

      const attrs = this.attrs.filter(item => idsSet.has(item.id))

      attrs.forEach(attr => {
        if (/^-*$/g.test(attr_ids)) {
          return
        }

        // 将mix_value长度从大到小排序, 避免匹配到错误的值
        const list = [...(attr.data || [])].sort((a, b) => (b.mix_value.length - a.mix_value.length))

        list.forEach(item => {
          const { id, mix_value } = item
          if (!(new RegExp(mix_value)).test(attr_ids)) {
            return
          }

          attr_ids = attr_ids.replace(mix_value, '')
          this.activeAttrs[id] ? this.activeAttrs[id].push(mix_value) : this.activeAttrs[id] = [mix_value]
        })
      })
    },
    // 处理商品类型标签, 功能和促销标签
    handleShopTypeTags({ data = {} }) {
      const { value, mall_tag } = data
      // 埋点
      if (value) {
        const index = (this.filterData?.cloudTags || []).findIndex(({ value: val }) =>  value === val )
        index > -1 && this.analysisCloudTagClick({ value, tagIndex: index + 1 })
      }
      return { tag_ids: value, mall_tag, pic_cate_id: '', ...this.getSourceInfo({ source: 'insideFilter', sourceStatus: value ? 1 : 0 }) }
    },
    // 侧边筛选栏属性配置
    handleSideAttrConfig () {
      this.attrs.forEach((attr, index) => {
        const activeIndexArr = !!this.tspsData.length ? [] : [0, 1] // 有tsp标签时不默认展开，否则默认展开前两项
        const curActiveAttr = this.activeAttrs[attr.id] || []
        // 选中属性项
        if (curActiveAttr) {
          activeIndexArr.push(index) // 有选中项就需要展开
        }

        const active = activeIndexArr.includes(index)
        this.menuBarConfig.push({
          text: attr.name,
          count: true,
          active
        })
        this.menuItemsConfig.push({
          type: attr.show_group ? 'img' : 'multi',
          collapse: index < 2 ? 2 : 0,
          data: attr,
          selectedMulti: attr.id == 27
            ? curActiveAttr
              .map(_ => _.split('-'))
              .reduce((acc, cur) => {
                return acc.concat(cur)
              }, [])
            : curActiveAttr || [],
        })
      })
    },
    // 侧边筛选栏价格滑块配置
    handlePriceRange (filterPrices) {
      const { min_price, max_price } = filterPrices || {}
      const { min_price: startMinPrice, max_price: startMaxPrice } = this.catInfo || {}
      if (!min_price && !max_price || min_price == max_price) return
      this.hasPriceRange = true
      if (startMinPrice && startMaxPrice) {
        filterPrices = {
          ...filterPrices,
          startMinPrice,
          startMaxPrice
        }
      }
      const barConfig = {
        text: `${this.language.SHEIN_KEY_PWA_16527}(${this.locals.currency})`,
        active: true,
        normal: true
      }
      const filterPriceIsInput = this.listAbtResult?.ListPriceFilter?.p?.ListPriceFilter === 'Input'
      const itemConfig = {
        type: 'price',
        data: filterPrices,
        isInput: filterPriceIsInput,
        language: {
          to: this.language.SHEIN_KEY_PWA_27244,
          min: this.language.SHEIN_KEY_PWA_27242,
          max: this.language.SHEIN_KEY_PWA_27243,
        }
      }

      this.priceRange = {
        isInput: this.isExportPrice,
        maxPrice: filterPrices.max_price,
        minPrice: filterPrices.min_price,
        startMaxPrice: filterPrices.startMaxPrice,
        startMinPrice: filterPrices.startMinPrice,
        language: itemConfig.language
      }

      // F/G分支下移动到product type下
      if (filterPriceIsInput) {
        this.menuBarConfig.unshift(barConfig)
        this.menuItemsConfig.unshift(itemConfig)
      } else {
        this.menuBarConfig.push(barConfig)
        this.menuItemsConfig.push(itemConfig)
      }
    },
    // 有图文导航时，剔除属性数据里面和图文重复的数据
    filterAttrFromPicTopNav() {
      let list = this.attrs // 默认属性

      if ((this?.filterPicTopNav || []).length > 1) {
        // 有图文导航时，剔除属性数据里面和图文重复的数据
        const mapTab = this.filterPicTopNav.reduce((obj, item) => {
          if (item.tabType == 2) {  // 属性项
            obj[item.tabId] = true
          }
          return obj
        }, {})

        list = list.reduce((arr, item) => {
          if (!mapTab[item.id]) {
            arr.push(item)
          }
          return arr
        }, [])
      }

      return list
    },
    // 优先tsp数据
    sortByTst(list) {
      if (this.filterConfig.exposureTsp && this.tspsData.length) {
        // 优先tsp数据
        return this.tspsData.map(item => {
          item.isTsp = true
          return item
        }).concat(list)
      }

      return list
    },
    getConfigByAttr(attr) {
      return attr.isTsp
        ? {
          type: 'tsp',
          dataType: 'tsp',
          data: attr
        }
        : {
          type: attr.show_group ? 'img' : 'multi',
          dataType: 'attr',
          data: attr
        }
    },
    getAttrTopTwoData() {
      let list = this.sortByTst(this.filterAttrFromPicTopNav())
      const firstAttr = list[0] || {}
      const secondAttr = list[1] || {}
      const firstAttrConfig = this.getConfigByAttr(firstAttr)
      const secondAttrConfig = this.getConfigByAttr(secondAttr)

      return { firstAttr, secondAttr, firstAttrConfig, secondAttrConfig }
    },
    // 中间2个坑位，下拉面板里面的数据
    handleTopTwoData () {
      if (this.firstIn) {
        this.topTwoData = {}
        const { firstAttr, secondAttr, firstAttrConfig, secondAttrConfig } = this.getAttrTopTwoData()
        const cate = { type: 'single', dataType: 'cate', data: this.cate }

        if (this.dailyDates.length) {
          this.topTwoData.topOne = { type: 'select', dataType: 'daily', data: this.dailyDates }
          if (this.cate?.data?.length && (!this.isAllCategoryType)) { // 图文导航不是所有都是品类 或 tiledListFilter === 'A'
            this.topTwoData.topTwo = cate
          } else if (Object.keys(firstAttr).length) {
            this.topTwoData.topTwo = firstAttrConfig
          }
        } else if (this.cate?.data?.length && (!this.isAllCategoryType)) {
          this.topTwoData.topOne = cate
          if (Object.keys(firstAttr).length) {
            this.topTwoData.topTwo = firstAttrConfig
          }
        } else {
          if (Object.keys(firstAttr).length) {
            this.topTwoData.topOne = firstAttrConfig
          }
          if (Object.keys(secondAttr).length) {
            this.topTwoData.topTwo = secondAttrConfig
          }
        }

      } else {
        // 更新中间两个坑位面板内的筛选数据
        Object.keys(this.topTwoData).forEach(key => {
          if (this.topTwoData[key].dataType == 'cate') {
            this.topTwoData[key].data = this.cate
          } else if (this.topTwoData[key].dataType == 'attr') {
            const targetAttr = this.attrs.find(item => item.id == this.topTwoData[key].data.id)
            if (targetAttr) {
              this.topTwoData[key].data = targetAttr
            }
          } else if (this.topTwoData[key].dataType == 'tsp') {
            const targetTsp = this.tspsData.find(item => item.id == this.topTwoData[key].data.id)
            if (targetTsp) {
              this.topTwoData[key].data = targetTsp
            }
          }
        })
      }
    },
    // 筛选Bar配置（标题、icon）
    handleNavConfig () {
      const { child_cat_id, attr_values, max_price } = this.queryParams
      const hasTopOne = this.topTwoData.topOne // 筛选坑位一
      let panelContentSelected =  true
      let sortIndex = -1
      if (this.filterConfig.isExportFilter) {
        sortIndex = this.sortMap.findIndex(item => item.value == this.queryParams.sort)
        panelContentSelected = sortIndex > -1
      }

      let navConfig = [
        { text: this.language?.SHEIN_KEY_PWA_15500, icons: this.icons, filterType: 'sort', panelContentSelected }
      ]

      if (this.filterConfig.isExportFilterSort) {
        handleNavConfOfSort(
          navConfig[0],
          this.sortMap[sortIndex > 0 ? sortIndex : 0]
        )
      }
      // 中间两个坑位筛选
      if (this.isExportFilter){
        const { language = {}, sortAllList = [] } = this

        if (this.filterConfig.isShowMostPopularBar) {
          const data = sortAllList.find(item => /popular/i.test(item.label_en))

          navConfig.push({
            active: data.value == this.myQuery?.sort || 0,
            text: language.SHEIN_KEY_PWA_15556,
            filterType: 'button',
            data
          })
        }


        if (this.filterConfig.isShowPriceBar) {
          const data = sortAllList.filter(item => /price/i.test(item.label_en))

          this.filterConfig.isShowPriceBar && navConfig.push({
            text: language.SHEIN_KEY_PWA_14953,
            filterType: 'price',
            active: Number(this.myQuery?.sort || 0),
            data: data
          })
        }



      } else if (hasTopOne) {
        Object.keys(this.topTwoData).forEach(key => {
          let { data, dataType } = this.topTwoData[key] || {}
          if (dataType == 'daily') {
            navConfig.push({ text: this.language.SHEIN_KEY_PWA_15190, icons: this.icons, filterType: 'date', panelContentSelected: true })
          } else if (dataType == 'cate') {
            navConfig.push({ text: this.language.SHEIN_KEY_PWA_14980, icons: this.icons, filterType: 'category', disable: !data.data.length, panelContentSelected: !!child_cat_id })
          } else if (dataType === 'tsp') {
            navConfig.push({ text: data?.name, icons: this.icons, filterType: 'tsp', disable: !this.tspsData.find(i => i.id == data.id), panelContentSelected: !!this.activeTsps[data.id] })
          } else if (dataType == 'attr') {
            navConfig.push({ text: data?.name, icons: this.icons, filterType: 'attribute', disable: !this.attrs.find(i => i.id == data.id), panelContentSelected: !!this.activeAttrs[data.id] })
          }
        })

        // 曝光sort
        analysis.exposeSortEntrance()
      }  else {
        const { sort = 0 } = this.myQuery
        navConfig = this.sortMap2.map(item => ({
          type: 'QuickSearch',
          text: item.label,
          value: item.value,
          active: item.value == sort,
          icon: item.label_en == 'Price Low To High' ? 'icon-price_px_' : item.label_en == 'Price High To Low' ? 'icon-price_px_-' : ''
        }))
      }

      // 侧边筛选
      if (hasTopOne || this.hasPriceRange || this.isExportFilter) {
        const styleKey = this.locals.GB_cssRight ? 'paddingLeft' : 'paddingRight'
        navConfig.push({
          text: this.language.SHEIN_KEY_PWA_15044,
          icons: { normal: 'sui_icon_filter_16px', isSuiIcon: true },
          hasSeperator: true,
          hasCountBadge: true,
          styles: { [styleKey]: '18px' },
          filterType: 'sideFilter',
          panelContentSelected: !!child_cat_id || !!attr_values || !!max_price
        })
        this.sidePanelIndex = this.isExportFilter
          ? 1
          : hasTopOne ? navConfig.length - 1 : 0 // 侧边筛选对应的index
      }

      this.navConfig = navConfig
    },
    handleNavBarType() {
      if (this.isExportFilter) {
        const {
          isShowPriceBar,
          isShowMostPopularBar,
          isExportFilterSort
        } = this.filterConfig
        this.navBarConfig = {
          type: NAVBAR_EXPORT,
          isShowPriceBar,
          isShowMostPopularBar,
          isExportFilter: this.isExportFilter,
          isExportFilterSort
        }
      } else if (this.navConfig.find(item => item.type === 'QuickSearch')) {
        this.navBarConfig = {
          type: NAVBAR_QUICK
        }
      } else {
        this.navBarConfig = {
          type: NAVBAR_PANEL
        }
      }
    },
    // 当左边有筛选时，全部展开，每行显示最多6行
    handleFilterLeft() {
      if (!this.filterConfig.filterLeftShow) return
      this.filterAllItemSpread(6)
    },
    // 侧边filter展开 item
    filterAllItemSpread(collapse) {
      if (this.isFilterfocus) {  // ada 全部展开
        collapse = 0
      }
      this.menuItemsConfig.forEach(item => {
        if ('collapse' in item) {
          item.collapse = collapse
        }
        if (item.data && 'show_group' in item.data) {
          item.data.show_group = true // 展开
        }
      })
      this.menuBarConfig.forEach(item => {
        item.active = true
      })
    },
    // 标签云点击埋点
    analysisCloudTagClick ({ value, tagCate, tagIndex, isPicNavCate, isCancel }) {
      analysis.clickCloudTag({
        tagId: value,
        tagCate,
        tagIndex,
        isCancel,
        facet: this.fhContext?.tag_id,
        gfilter: this.googleContext?.gfilter,
        isPicNavCate
      })
    },
    // 点击外露标签
    clicAttrTag ({ active, data = {}, value, dataType }) {
      const { id } = data
      this.activeAttrCloudTagId = id || value
      const list = Array.isArray(this.cloudTagParams) && this.cloudTagParams.length ? this.cloudTagParams : this.cloudTagParams?.data || []

      const index = list.findIndex((item) => [id, value].includes(item.value))
      let _value = id
      let tagCate = 'attribute'
      if (dataType === 'daily') {
        _value = 'date'
        tagCate = 'date'
      } else if (dataType === 'cate') {
        _value = 'category'
        tagCate = 'category'
      } else if (dataType === 'price') {
        _value = 'price'
        tagCate = 'price'
      }
      index > -1 && this.analysisCloudTagClick({ value: _value, tagCate, tagIndex: index + 1, isCancel: active ? 1 : 0 })
    },
    // 点击筛选bar
    clickBar (payload) {
      this.exposeSort()
      handleClickBarAnalysis(this.topTwoData, payload, this)
    },
    onRenderPanel(type, firstClickedItem) {
      if (this.filterBarPopupsVisible || this.isRenderingPanel) {
        return
      }
      this.isRenderingPanel = true
      this.loadedPanelCount = 0
      this.filterBarPopupsVisible = true
      this.firstClickedItem = firstClickedItem || {}
      this.firstClickedItem._clickType = type
    },
    onPanelMounted() {
      if (this.panelMounted) {
        return
      }
      this.loadedPanelCount += 1
      if (this.loadedPanelCount >= this.panelCount) {
        // 说明所有的 panel 都已经渲染好了
        this.panelMounted = true
      } else {
        return
      }

      // if (this.$refs.refFilter) {
      //   if (panelName === 'filter_sidebar') {
      //     // 说明 panel 都已经渲染好了, 这里用侧边栏的 panel 来判断所有 panel 是否渲染好, 因为它渲染的最慢
      //     this.panelMounted = true
      //   } else {
      //     return
      //   }
      // } else {
      //   this.panelMounted = true
      // }

      this.$nextTick(() => {
        this.isRenderingPanel = false
        this.$refs.refFilter?.initPanelData?.()
        this.$refs.refCloudTags?.initPanelData?.()
        if (this.firstClickedItem._clickType === 'filter') {
          this.$refs.refFilter.onPanelMounted(this.firstClickedItem)
        } else {
          this.$refs.refCloudTags.onPanelMounted(this.firstClickedItem)
        }
      })
    },
    // 关闭属性面板
    closeCloudTagPanel() {
      this.activeAttrCloudTagId = null
      this.$refs.refCloudTags?.hideAllPanel?.()
    },
    exposeSort() {
      const sort = Number(this.queryParams.sort || 0)
      let clickType = 'sort'
      const sortMapIndex = this.sortMap.findIndex(item => item.value === sort)

      if (sortMapIndex < 0) {
        this.navConfig.forEach((item, index) => {
          const { data } = item

          if (data?.value === sort || (Array.isArray(data) && data.find(obj => obj.value === sort))) {
            clickType = `top${index}`
          }
        })
      }

      analysis.exposeSort({
        clickType,
        sort: this.queryParams.sort || 0
      })
    },
    openSideFilter () {
      if (this.sidePanelIndex || this.sidePanelIndex === 0) {
        this.$refs.refFilter.clickBar({ activeIndex: this.sidePanelIndex })
      }
    },
    /**
     * 图文导航点击
     * @param {Object} item 图文导航项
     * @param {Object} tabItem 图文导航tab
     * @param {Boolean} flag 是否选中
     * @param {Boolean} isJump 是否点击跳转
     */
    selectPicTopNav(item, tabItem, flag, isJump = false) {
      const { pageName } = this.catInfo
      const isAnalysisJumpType = ['page_real_class', 'page_select_class'].includes(pageName)// 是否上报jump_type[pageId=1385050990]
      const isAnalysisNavName = ['page_real_class', 'page_select_class', 'page_search'].includes(pageName) // 是否上报nav_name[pageId=1384144159]
      const { nav_id, nav_type, nav_name, goodsId = '', index, rec_type } = item
      const params = {
        pic_nav_adp: goodsId,
        pic_nav_id: nav_id,
        pic_nav_type: nav_type,
        pic_nav_pos: index + 1,
        pic_rec_type: rec_type,
        pic_tab_id: tabItem.tabId,
        pic_tab_type: tabItem.tabType,
        pic_tab_pos: tabItem.tabIndex + 1,
      }
      isAnalysisJumpType && (params.pic_tab_jump_type = isJump)
      isAnalysisNavName && (params.pic_nav_name = nav_name)
      if (!flag) {
        Object.keys(params).forEach(key => params[key] = '')
      }

      if (isJump) {
        params.pic_tab_jump_type = isJump
        markPoint('toNextPageClick', 'public')
        analysis.clickPicTopNav(params)
        const extraQuery = {
          nav_query_adp: goodsId,
          nav_query_id: nav_id,
          nav_query_type: nav_type,
          nav_query_pos: index + 1,
          nav_query_name: nav_name,
          nav_query_rec_type: rec_type,
          nav_jump_type: isJump ? 1 : 2,
          ...this.getSourceInfo({ source: 'imgNavQuery', sourceStatus: 1 })
        }
        this.jumpNewPage(item, extraQuery)
        return
      }

      // 命中topNav2Query实验
      if (this.picTopNav2QueryIsEnabled) {
        params.nav_to_query = 1 // 标识点击图文导航跳转到query
        params.pic_nav_name = nav_name
        flag && analysis.clickPicTopNav(params)
        this.picTopNav2QueryDoSearch(item)
        return
      }

      this.queryParams = Object.assign(
        {}, 
        this.initialQueryParams, 
        { sort: this.queryParams.sort, }, 
        params, 
        this.getSourceInfo({ 
          source: 'imgNav', 
          sourceStatus: flag ? 1 : 0 
        })
      )

      flag && analysis.clickPicTopNav(params)
      this.resetData = true // 重置filter
      this.firstIn = false  // 点击图文导航 直接取消
      this.notFilterCloudTags = true
      this.activeAttrCloudTagId = null
      appEventCenter.$emit('hidePanel')
    },

    jumpNewPage (item, extraQuery = {}) {
      const { nav_name, nav_id, goodsId } = item
      const { src_identifier, src_module, src_tab_page_id, } = this.$route.query
      const query = { adp: goodsId, src_identifier, src_module, src_tab_page_id, ...extraQuery }
      const id = String(nav_id).replace(/^3000/, '')

      this.$router.push({
        query,
        path: `${encodeURIComponent(nav_name)}-c-${id}.html`,
      })
    },

    // 图文导航所有元素都是品类,不外露在坑位2
    handlePicTopNav() {
      this.isAllCategoryType = false
      if (!(this?.filterPicTopNav || []).length) return
      const list = this.filterPicTopNav.reduce((arr, item) => {
        arr.push(...item.navs)
        return arr
      }, [])
      this.isAllCategoryType = list.every(_ => _.nav_type == '1')
    },
    // 获取图文导航高度
    updatePicNavHeight({ tabY = 0 }) {
      this.filterFixedUpAddY = tabY // scrollMixin配置属性
      this.handleComponentMouted('picTopNavName')
    },
    async handlePicNavCSR () {
      this.updatePicNavSkeletonHeight()
      await this.$nextTick()
      onceEventForCSR('PicTopNav')
    },
    async updatePicNavSkeletonHeight () {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        this.picTopNavSkeletonHeight = this.$refs?.refFixedPicTopNav?.$el?.querySelector('.content')?.offsetHeight || 0
      }, 300)
    },
    // 获取navbar不同排序，不同sortType
    getSortType() {
      const {
        isExportFilter,
        isShowMostPopularBar,
        isShowPriceBar,
      } = this.filterConfig

      if (!isExportFilter) return 'sort'
      if (isShowMostPopularBar && isShowPriceBar) return 'top1,top2'
      if (isShowMostPopularBar) return 'top1'
      if (isShowPriceBar) return 'top2'
      
    },
    resetCloutTagPanelHeight() {
      this.getAllRefPanel().forEach(item => {
        item.show && item.calHeight()
      })
    },
    getAllRefPanel() {
      const toList = (arr) => {
        if (!arr) return []

        return Array.isArray(arr)
          ? arr
          : [arr]
      }

      return [
        ...toList(this.$refs.refSortPanel),
        ...toList(this.$refs.refTopTwoPanel),
        ...toList(this.$refs.refFilterPanel),
        ...toList(this.$refs.refCloudTagsPanel),
      ]
    },
    // 切换页面
    switchPath() {
      this.closeCloudTagPanel()
      this.handleFilterReset()
      this.resetScrollFixed()
    },
    hideAllPanel () {
      this.$refs.refFilter?.hideAllPanel?.()
      this.closeCloudTagPanel()
    },
    panelShow(type) {
      this.hasPanelShow = type
      this.$emit('panelShow', type)
    },
    handleFixedChange (type, isFixed) {
      type === this.TYPE_MAP.PIC_TOP_NAV && (this.picTopNavIsSticky = isFixed)
      this.$emit('fixedChange', isFixed)
    },
    handleChangeOneClickTagId(tagId) {
      this.filterChange({
        data: {
          value: tagId,
          tagIndex: 0,
        },
        type: 'cloudTag'
      })
    },
    getSourceInfo({ source, sourceStatus }) {
      return {
        source,
        source_status: sourceStatus,
      }
    },
  }
}
</script>

<style lang="less">
.product-list-filter {
  position: relative;
  &.loading {
    &.high-layer {
      z-index: @zindex-modal-mask;
    }
    /* stylelint-disable selector-max-specificity,declaration-no-important */
    #scrollfixed-pictop-navbar {
      z-index: @zindex-modal-mask !important;
      &.fixed {
        .content {
          z-index: @zindex-modal-mask !important;
        }
      }
    }
  }
}
</style>
