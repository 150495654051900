var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    {
      'picTopNav-container__circle-small': _vm.isSmall && (_vm.isJump || _vm.toQuery),
      'picTopNav-container__ptop12': (!_vm.isShowBgBanner && !_vm.showTabs && !(_vm.isSmall && _vm.isJump)) || (_vm.isShowBgBanner && _vm.isSticky),
      'picTopNav-container__hide': _vm.isScrollHide,
    },
    'picTopNav-container',
    'fsp-element',
  ],attrs:{"fsp-key":"products_picTopNav_fsp"}},[(_vm.showTabs)?_c('NavTabs',{attrs:{"list":_vm.picTopTabs,"reverse":_vm.locals.GB_cssRight},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}}):_vm._e(),_vm._v(" "),_c('SlideContent',{class:_vm.contentClass,attrs:{"reverse":_vm.locals.GB_cssRight},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.picTopTabs),function(tabItem,index){return _c('SlideContentItem',{key:index,attrs:{"is-circle":_vm.isCircle,"is-small":_vm.isSmall}},[(_vm.tabIndex === index || !_vm.firstRendering)?_c('PicTopNav',{ref:"picTopNavEl",refInFor:true,attrs:{"is-jump":_vm.isJump,"to-query":_vm.toQuery,"disabled":_vm.currentTabIndex !== index,"pic-top-nav-list":tabItem.navs,"is-small":_vm.isSmall,"lazy-img":_vm.locals.LAZY_IMG,"reverse":_vm.locals.GB_cssRight,"next-text":_vm.showTabs && _vm.picTopTabs.length - 1 > index ? _vm.nextText : '',"tab-item":tabItem,"tab-index":index,"cat-info":_vm.catInfo,"first-rendering":_vm.firstRendering},on:{"done":_vm.handleSlideDone,"select":_vm.handleSelect},model:{value:(_vm.picIndex),callback:function ($$v) {_vm.picIndex=$$v},expression:"picIndex"}}):_vm._e()],1)}),1),_vm._v(" "),(!_vm.hiddenBottomGap)?_c('div',{staticClass:"picTopNav-container__bottom not-fsp-element",class:{'picTopNav-container__bottom-hide': _vm.isCircle && _vm.isSmall}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }