<template>
  <div
    :class="[
      {
        'picTopNav-container__circle-small': isSmall && (isJump || toQuery),
        'picTopNav-container__ptop12': (!isShowBgBanner && !showTabs && !(isSmall && isJump)) || (isShowBgBanner && isSticky),
        'picTopNav-container__hide': isScrollHide,
      },
      'picTopNav-container',
      'fsp-element',
    ]"
    fsp-key="products_picTopNav_fsp"
  >
    <!-- title -->
    <!-- <div
      v-if="isJump && !isSmall"
      :class="{
        'picTopNav-container__title': true,
        'picTopNav-container__title-small': isSmall
      }"
    >
      {{ language.SHEIN_KEY_PWA_26292 }}
    </div> -->

    <!-- tab -->
    <NavTabs
      v-if="showTabs"
      v-model="tabIndex"
      :list="picTopTabs"
      :reverse="locals.GB_cssRight"
    />

    <!-- 内容 -->
    <SlideContent 
      v-model="tabIndex" 
      :reverse="locals.GB_cssRight"
      :class="contentClass"
    >
      <SlideContentItem 
        v-for="(tabItem, index) in picTopTabs" 
        :key="index"
        :is-circle="isCircle"
        :is-small="isSmall"
      >
        <PicTopNav
          v-if="tabIndex === index || !firstRendering"
          ref="picTopNavEl"
          v-model="picIndex"
          :is-jump="isJump"
          :to-query="toQuery"
          :disabled="currentTabIndex !== index"
          :pic-top-nav-list="tabItem.navs"
          :is-small="isSmall"
          :lazy-img="locals.LAZY_IMG"
          :reverse="locals.GB_cssRight"
          :next-text="showTabs && picTopTabs.length - 1 > index ? nextText : ''"
          :tab-item="tabItem"
          :tab-index="index"
          :cat-info="catInfo"
          :first-rendering="firstRendering"
          @done="handleSlideDone"
          @select="handleSelect"
        />
      </SlideContentItem>
    </SlideContent>

    <div
      v-if="!hiddenBottomGap"
      class="picTopNav-container__bottom not-fsp-element"
      :class="{'picTopNav-container__bottom-hide': isCircle && isSmall}"
    ></div>
  </div>
</template>

<script>
import NavTabs from './components/NavTabs.vue'
import PicTopNav from './components/content/PicTopNav.vue'
import SlideContent from './components/content/SlideContent.vue'
import SlideContentItem from './components/content/SlideContentItem.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'

// const RATIO = 2.51282051282 // 大图和小图的比例差， 小图文 * ratio = 大图
const RATIO = 2 // 大图和小图的比例差， 小图文 * ratio = 大图

export default {
  name: 'PicTopNavContainer',
  components: {
    NavTabs,
    PicTopNav,
    SlideContent,
    SlideContentItem,
  },
  props: {
    picTopTabs: {
      type: Array,
      default: () => [],
    },
    isSmall: Boolean, // 是否固定
    locals: {
      type: Object,
      default: () => ({}),
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    // 有多个tab时， 图文元素最后一个元素的文案
    nextText: {
      type: String,
      default: '',
    },
    isScrollHide: {
      type: Boolean,
      default: false
    },
    listAbtResult: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    catInfo: {
      type: Object,
      default: () => ({})
    },
    isShowBgBanner: {
      type: Boolean,
      default: false
    },
    hiddenBottomGap: {
      type: Boolean,
      default: false
    },
    toQuery: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tabIndex: 0, // 切换的tabindex
      picIndex: -1, // 当前选中的图文导航元素
      currentTabIndex: -1, // 当选中图文后的tabindex, 用于判断是否展示内容
      moveY: 0,
      contentClass: '',
      // 是否是首屏渲染
      firstRendering: true,
    }
  },

  computed: {
    showTabs() {
      return this.picTopTabs.length > 1
    },
    // 是否点击图文类型跳转新页面
    isJump () {
      return this.picTopTabs?.[0]?.is_blank
    },
    isCircle () {
      return this.isJump || this.toQuery
    }
  },

  watch: {
    // 切换列表触发
    picTopTabs: {
      handler() {
        this.getInnerIndex()
        this.updateInner()
        const lists = this.$refs?.picTopNavEl || []
        lists.forEach((el) => el.reset())
      },
      deep: true
    },

    isSmall() {
      this.contentClass = ''
      window.requestAnimationFrame(() => {
        this.contentClass = `picTopNav-container__animation`
      })
    },
    
    tabIndex() {
      this.firstRendering = false
      this.$refs?.picTopNavEl?.forEach((vm) => vm.resetMovex())
    }
  },

  created() {
    this.getInnerIndex()
  },

  mounted() {
    this.updateInner()
    if (this.picTopTabs.length === 1 && this.picTopTabs[0]?.tabId) {
      // 曝光tab
      setTimeout(() => {
        daEventCenter.triggerNotice({
          daId: '2-17-21',
          bindData: [{
            ...this.picTopTabs[0],
            index: 0
          }]
        })
      }, 1000)
    }
  },

  methods: {
    updateInner() {
      this.$nextTick(() => {
        const tabEl = document.querySelector('.picTopNav-tab')
        const tabPadding = document.querySelector('.picTopNav-container__bottom')?.offsetHeight || 0 // 头部的padding
        const tabHeight = tabEl?.offsetHeight || 0
        const tabY = Math.max(tabHeight - tabPadding, 0)
        const contentHeight = document.querySelector('.slide-content')?.offsetHeight || 0
        this.moveY = contentHeight - contentHeight / RATIO
        const neglectHeight = parseInt(this.moveY + tabY)
        
        this.$emit('updatePicNavHeight', { neglectHeight, tabY })
      })
    },

    // next 下一页 or 上一页 滑动事件
    handleSlideDone(type) {
      if (this.picTopTabs.length < 2) return
      const maxLimit = this.picTopTabs.length - 1 > this.tabIndex // 最后一页不翻页
      if (type === 'next' && maxLimit) {
        this.tabIndex += 1
      }
      if (type === 'last' && this.tabIndex > 0) {
        this.tabIndex -= 1
      }
    },

    handleSelect(navPicItem, index, isSelect) {
      this.firstRendering = false
      this.currentTabIndex = this.tabIndex
      const tabItem = this.showTabs
        ? {
          ...(this.picTopTabs[this.currentTabIndex] || {}),
          tabIndex: this.currentTabIndex,
        }
        : {}
      this.$emit('selectPicTopNav', { ...navPicItem, index }, tabItem, isSelect, this.isJump)
    },

    // 外部改变当前图搜值
    setInnerIndex(index) {
      this.picIndex = index
    },

    // 进入页面时，查找是否有选中
    getInnerIndex() {
      const { pic_nav_id, pic_tab_id } = this.$route?.query || {}

      if (!this.showTabs) {
        const { navs = [] } = this.picTopTabs[0] || {}
        this.tabIndex = 0
        this.currentTabIndex = 0
        this.picIndex = navs.findIndex((item) => item.nav_id == pic_nav_id)
      } else {
        const findIndex = this.picTopTabs.some((tabItem, tabI) => {
          if (tabItem.tabId == pic_tab_id) {
            this.tabIndex = tabI
            this.currentTabIndex = tabI
            this.picIndex = tabItem.navs.findIndex(
              (item) => item.nav_id == pic_nav_id
            )
            return true
          }
        })
        if (!findIndex) {
          this.tabIndex = 0
          this.currentTabIndex = -1
          this.picIndex = -1
        }
      }
    },
  },
}
</script>

<style lang="less">
.picTopNav-container {
  position: relative;
  background-color: #fff;
  z-index: @zindex-hack;

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 12px 12px;
  }
  
  &__title-small {
    margin-bottom: 6px;
  }

  &__circle-small {
    padding-top: 0.16rem /* 6/37.5 */;
    padding-bottom: 0.16rem /* 6/37.5 */;
  }

  &__ptop12 {
    padding-top: 0.32rem /* 12/37.5 */;
  }

  &__bottom {
    // margin-top: 0.32rem /* 12/37.5 */;
    margin-top: 8 / 37.5rem;
    height: 0.2667rem /* 10/37.5 */;
    background-color: #f5f5f5;
    transition: 'all .3s';
  }

  &__bottom-hide {
    height: 0;
    margin-top: 3 / 37.5rem;
  }


  &__hide {
    opacity: 0;
    transition: 'all .3s';
    transform: translate3d(0, -1.24rem, 0); 
   }

  @keyframes slideopacity {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 0.25;
    }

    50% {
      opacity: 0.5;
    }

    75% {
      opacity: 0.75;
    }

    100% {
      opacity: 1;
    }
  }

  &__animation {
    animation: slideopacity .4s;
    animation-iteration-count: 1;
    -webkit-animation: slideopacity .4s; /* Safari and Chrome */
    -webkit-animation-iteration-count: 1;
  }
}
</style>
