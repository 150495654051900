export class SC {
  #NAME_MAP = {
    entity: 'page_real_class',
    selection: 'page_select_class',
    selling: 'page_selling_point',
    daily_new: 'page_daily_new',
    search: 'page_search',
  }

  resetPageName() {
  }
}
