<template>
  <div style="background-color: #fff">
    <div
      v-if="type === 'pictopnav'"
      class="pictopnav-skel"
    >
      <span
        v-for="n in 4"
        :key="n"
        class="pictopnav-skel__i"
      ></span>
    </div>
    <div
      v-if="!type || type === 'filter'"
      class="filter-skel"
    >
      <span
        v-for="n in 4"
        :key="n"
        class="filter-skel__i"
      ></span>
    </div>
    <div
      v-if="!type || type === 'cloud'"
      class="cloud-skel"
    >
      <span
        v-for="n in 4"
        :key="n"
        class="cloud-skel__i"
      ></span>
    </div>
    <div
      v-if="!type && !noTitle"
      class="title-skel"
    ></div>
    <div
      v-if="!type"
      class="list-skel"
    >
      <div
        v-for="n in 4"
        :key="n"
        class="list-skel__i"
      >
        <div class="list-skel__i_f"></div>
        <div class="list-skel__i_s"></div>
        <div class="list-skel__i_t"></div>
        <div class="list-skel__i_fo"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    noTitle: Boolean,
  },
  created() {
    new Image().src = gbCommonInfo?.LAZY_IMG
  },
}
</script>
<style lang="less">
.pictopnav-skel {
  display: flex;
  padding: 0.32rem;
  border-bottom: 1px solid #e5e5e5;
  &__i {
    display: block;
    width: 1.87rem;
    height: 2.64rem;
    margin-right: 10px;
    background: #f5f5f5;
  }
}
.filter-skel {
  display: flex;
  height: 1.24rem;
  padding: 0.32rem;
  border-bottom: 1px solid #e5e5e5;
  justify-content: space-between;
  &__i {
    width: 1.84rem;
    margin-right: 0.2rem;
    background-color: #f6f6f6;
  }
}
.cloud-skel {
  display: flex;
  padding: 0.32rem;
  &__i {
    width: 1.84rem;
    height: 0.6933rem;
    margin-right: 0.2rem;
    background-color: #f6f6f6;
    border: 1px solid #f6f6f6;
    box-sizing: content-box;
  }
}
.title-skel {
  width: 2.4rem;
  height: 0.4rem;
  margin: 0.32rem 0.32rem;
  background-color: #f6f6f6;
}
.list-skel {
  padding: 0 0.32rem;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  &__i {
    width: 4.52rem;
    > div {
      background-color: #f6f6f6;
    }
    &_f {
      height: 6.02rem;
    }
    &_s {
      width: 1.867rem;
      height: 0.453rem;
      margin: 0.32rem 0 0.266rem;
    }
    &_t {
      width: 4.5334rem;
      height: 0.32rem;
    }
    &_fo {
      width: 2.667rem;
      height: 0.32rem;
      margin: 3px 0 0.64rem;
    }
  }
}
</style>
