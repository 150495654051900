import ResizeObserver from 'resize-observer-polyfill'

export const resizeObServer = (el, cb) => {
  const resizeObserver = new ResizeObserver(entries => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return
      }
      cb && cb(entries)
    })
  })
  resizeObserver.observe(el)
  return resizeObserver
}


/* 查找当前值在数组中的最大索引， 找不到返回-1 */ 
export const findIndex = (arr, findValue) => {
  let index = -1
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    const nextItem = arr[i + 1]
    if (item <= findValue && nextItem > findValue) {
      index = i
      break
    }
  }
  return index
}
