class MoutedQueue {
  constructor({ cb } = {}) {
    this.set = new Set()
    this.cb = cb
  }

  init(names = []) {
    if (!names.length) {
      this.cb && this.cb()
    }

    // 将所有的组件name存入初始中。
    names.forEach(name => {
      this.set.add(name)
    })
  }

  examine(name = '') {
    if (this.set.has(name)) { // 当前组件name注册完成后，从队列中删除
      this.set.delete(name)
    }
    
    if (!this.set.size) { // 等所有的name执行完后触发回调
      this.cb && this.cb()
    }
  }

  clear() {
    this.set = this.set.clear()
  }
}

export default MoutedQueue
